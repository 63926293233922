<template>
  <div class="fr-card">
    <slot />
  </div>
</template>

<style lang="sass">

.fr-card
  @apply border-fr-gray-500/50 border-[0.5px]

.dark
  .fr-card
    @apply border-fr-gray-0/30

// #NEO-TAG
.neo
  .fr-card
    @apply bg-fr-gray-50 rounded-sm

    .fr-card-hover
      @apply hover:bg-fr-gray-100 hover:border-fr-secondary-500

    .fr-card-footer
      @apply border-t border-fr-gray-300 bg-fr-gray-300

  &.dark
    .fr-card
      @apply bg-fr-gray-900

      .fr-card-hover
        @apply hover:bg-fr-gray-800 hover:border-fr-secondary-500

      .fr-card-footer
        @apply border-fr-gray-200/40 bg-fr-gray-850

// #CHOWEY-TAG
.chowey
  .fr-card
    @apply bg-fr-gray-200 rounded-lg

    &:has(footer)
      .fr-card-body
        @apply rounded-b-none

    .fr-card-footer
      @apply border-t bg-fr-gray-100 border-fr-gray-100

    .fr-card-hover
      @apply border border-fr-gray-300 hover:bg-fr-gray-300 hover:border-fr-secondary-500

  &.dark
    .fr-card
      @apply bg-fr-gray-800

      .fr-card-footer
        @apply bg-fr-gray-800 border-b border-fr-gray-700

      .fr-card-hover
        @apply border border-fr-gray-700 hover:bg-fr-gray-700 hover:border-fr-secondary-500
</style>
